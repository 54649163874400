/* You can add global styles to this file, and also import other style files */
.group-grid{
    display: flex;
}
.grid-1{
    flex: 1;
    background: #fff;
    margin: 7px;
    border-radius: 14px;
}

.note-textalign{
    text-align: center;
}
/*****************media Query************************/
@media screen and (max-width: 768px) {
    .group-grid{
        display: block;
    }
    .grid-1{
        flex: inherit;
        background: inherit;
        margin: 0px;
        border-radius: 0px;
    }
  }